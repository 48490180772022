function base64url(source) {
	// Encode in classical base64
	encodedSource = CryptoJS.enc.Base64.stringify(source);

	// Remove padding equal characters
	encodedSource = encodedSource.replace(/=+$/, '');

	// Replace characters according to base64url specifications
	encodedSource = encodedSource.replace(/\+/g, '-');
	encodedSource = encodedSource.replace(/\//g, '_');

	return encodedSource;
}
function getJwtToken(JSONData) {
	var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify({
		"alg" : "HS256",
		"typ" : "JWT"
	}))
	var encHeader = base64url(stringifiedHeader);

	var payLoad = {
		"iss" : JSONData.clientId,
		"sub" : JSONData.identity,
		"isAnonymous" : JSONData.isAnonymous
	}
	var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payLoad))
	var encPayLoad = base64url(stringifiedData);

	var result = CryptoJS.HmacSHA256(encHeader + "." + encPayLoad,
			JSONData.clientSecret);
	var resultSignature = base64url(result)

	var jwt = encHeader + "." + encPayLoad + "." + resultSignature;
	return jwt;
}
